import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="depositor"
export default class extends Controller {
  static targets = ["fullName", "depositor"];

  connect() {
  }

  handleKeyup(e) {
    const trimmedValue = this.fullNameTarget.value.trim().replace(/\s+/g, "");
    this.fullNameTarget.value = trimmedValue;
    this.depositorTarget.value = trimmedValue;
  }
}
